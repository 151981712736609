<template>
  <v-card>
    <v-card-text>
      <v-data-table
        fixed-header
        height="100vh"
        v-bind="dataTableAttrs"
        :headers="headers"
        :items="list"
        :loading="isLoading"
        :options.sync="options"
        :server-items-length="total"
        :items-per-page.sync="itemsPerPage"
        v-model="selectedItems"
        @update:items-per-page="handleItemsPerPageUpdated"
      >
        <template v-slot:top>
          <rs-table-title-bar
            v-if="$refs.headSearch"
            title="Gider Fatura Ödenmiş"
            icon="mdi-cash-check"
            @clear-filters="clearFilters()"
            @click:edit="handleEditClick"
            @reload="loadList"
            :edit-enabled="selectedItems.length === 1"
            :search.sync="search.query"
            v-bind="titleBarAttrs"
          >
            <template slot="buttons:prepend">
              <rs-select
                class="float-end me-2"
                style="max-width: 150px"
                label="Tarih Filtrele"
                :items="dateFilterList"
                @change="handleDateFilterChange"
                v-model="filterdate"
                dense
              />
            </template>
          </rs-table-title-bar>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.prepend="{ headers }">
          <rs-table-head-search
            :headers="headers"
            :search.sync="search"
            ref="headSearch"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.edit="{ item }">
          <router-link
            :to="{
              name: 'expenses.invoices.edit',
              params: { id: item.invoice_id },
            }"
          >
            <i class="menu-icon mdi mdi-pencil"></i>
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:body.append="{ headers }">
          <rs-table-foot-totals
            :headers="headers"
            :totals="footTotals"
            v-if="list.length > 0"
          />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.provider="{ item, value }">
          <router-link
            title="Tedarikçi Tanımına Git"
            v-if="item.provider_id"
            :to="{
              name: 'definitions.cluster-providers.edit',
              params: { id: item.provider_id },
            }"
          >
            {{ value }}
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.has_installments="{ item }">
          {{ item.has_installments ? "Taksitli" : "Tek Ödeme" }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.amount="{ value }">
          <rs-table-cell-number price :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.invoiced_type_id="{ item }">
          {{ item.invoiced_type }}
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.collected_on="{ value, item }">
          <router-link
            :to="{
              name: 'expenses.invoices.show',
              params: { id: item.invoice_id },
            }"
          >
            <rs-table-cell-date :value="value" />
          </router-link>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.bank_safe="{ value, item }">
          <router-link
            v-if="item.bank_account_id && can('see-bank-account')"
            :to="{
              name: 'definitions.bank-accounts.show',
              params: { id: item.bank_account_id },
            }"
          >
            {{ item.bank_safe }}
          </router-link>
          <router-link
            v-else-if="item.safe_id && can('see-safe')"
            :to="{
              name: 'definitions.safes.show',
              params: { id: item.safe_id },
            }"
          >
            {{ item.bank_safe }}
          </router-link>
          <template v-else>{{ item.bank_safe }}</template>
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.billed_on="{ value }">
          <rs-table-cell-date :value="value" />
        </template>

        <!-- eslint-disable-next-line -->
        <template v-slot:item.mixed_description="{ value }">
          <rs-table-cell-string limit="20" :value="value" />
        </template>
      </v-data-table>
    </v-card-text>
  </v-card>
</template>

<script>
import { mapGetters } from "vuex";

import { debounce } from "@/core/plugins/debounce";
import { filtersToURL, hasExport, hasPermissions } from "@/view/mixins";

export default {
  mixins: [filtersToURL, hasExport, hasPermissions],
  computed: {
    ...mapGetters(["clusterId", "invoicedTypeList", "projects"]),
  },
  data() {
    return {
      filterdate: 0,
      headers: [
        {
          text: "",
          value: "edit",
          searchable: false,
          sortable: false,
          width: "30px",
          align: "center",
        },
        {
          text: "Tarih",
          value: "collected_on",
          searchable: "date",
        },
        {
          text: "Tedarikçi",
          value: "provider",
          searchable: "text",
        },
        {
          text: "Gider Türü",
          value: "expense_type",
          searchable: "text",
        },
        {
          text: "Gider Tipi",
          value: "child_expense_type",
          searchable: "text",
        },
        {
          text: "Gider-Ödeme Açıklama",
          value: "mixed_description",
          searchable: "text",
          width: "120px",
        },
        {
          text: "Tutar",
          value: "amount",
          searchable: "number",
          align: "end",
        },
        {
          text: "Ödeme Hesabı",
          value: "bank_safe",
          searchable: "text",
        },
        {
          text: "Belge Tarihi",
          value: "billed_on",
          searchable: "date",
        },
        {
          text: "Belge No",
          value: "invoice_no",
          searchable: "text",
        },
        {
          text: "Ödeme Şekli",
          value: "has_installments",
          searchable: "select",
          options: () => [
            { id: false, name: "Tek Ödeme" },
            { id: true, name: "Taksitli" },
          ],
        },
        {
          text: "Fatura Tipi",
          value: "invoiced_type_id",
          searchable: "multiselect",
          options: () => this.invoicedTypeList,
        },
        {
          text: "Yıl",
          value: "year",
          searchable: "number",
        },
        {
          text: "Ay",
          value: "month",
          searchable: "number",
        },
        {
          text: "Proje",
          value: "project",
          searchable: "select",
          options: () => this.projects,
        },
      ],
      dateFilterList: [
        {
          id: 0,
          name: "Tümü",
        },
        {
          id: 1,
          name: "Bugün",
        },
        {
          id: 2,
          name: "Son 7 Gün",
        },
        {
          id: 3,
          name: "Son 30 Gün",
        },
        {
          id: 4,
          name: "Son 6 Ay",
        },
        {
          id: 5,
          name: "Son 1 Yıl",
        },
        {
          id: 6,
          name: "Son 2 Yıl",
        },
      ],
      options: {
        sortBy: [],
        sortDesc: [],
      },
      search: {
        billed_on: {
          min: undefined,
          max: undefined,
        },
      },
      titleBarAttrs: {
        exportUrl: "expense/invoice-item-payments",
        exportParams: this.getParams,
      },
    };
  },
  mounted() {
    this.generateExportColumns(this.headers);
  },
  methods: {
    getParams() {
      const params = Object.assign(
        this.buildSearchParams(this.search, this.options)
      );

      params.cluster_id = [this.clusterId];

      return params;
    },
    clearFilters() {
      this.$refs.headSearch.reset();
      this.filterdate = null;
      this.search.query = "";
      this.search.billed_on = {
        min: undefined,
        max: undefined,
      };
      this.loadList();
    },
    loadList() {
      if (this.isLoading) {
        return;
      }

      this.isLoading = true;
      this.list = [];
      this.footTotals = [];

      const params = this.getParams();

      this.$api
        .query("expense/invoice-item-payments", { params })
        .then((response) => {
          const list = [];

          if (response.data && response.data.data) {
            for (const item of response.data.data) {
              list.push(item);
            }
          }

          this.list = list;

          if (response.data && response.data.meta) {
            this.total = response.data.meta.total;
            this.options.page = response.data.meta.current_page;
            this.itemsPerPage = response.data.meta.per_page;
            this.options.itemsPerPage = this.itemsPerPage;
            this.footTotals = response.data.meta.totals;
          }
        })
        .catch((error) => {
          this.handleError(error);
        })
        .finally(() => (this.isLoading = false));
    },
    handleEditClick(item) {
      if (!item.invoice_id) {
        item = this.selectedItems[0];
      }

      if (this.can("edit-invoice")) {
        this.$router.push({
          name: "expenses.invoices.edit",
          params: { id: item.invoice_id },
        });
      } else {
        this.$router.push({
          name: "expenses.invoices.show",
          params: { id: item.invoice_id },
        });
      }
    },
    handleDateFilterChange(value) {
      const t = new Date();

      if (this.search.billed_on === undefined) {
        this.search.billed_on = {
          min: undefined,
          max: undefined,
        };
      }

      if (value === null) {
        this.search.billed_on = {
          min: undefined,
          max: undefined,
        };
      } else if (value === 1) {
        const todaystart = new Date();
        todaystart.setUTCHours(0, 0, 0, 0);
        const todayend = new Date();
        todayend.setUTCHours(23, 59, 59, 999);
        // payment in today
        this.search.billed_on = {
          min: todaystart,
          max: todayend,
        };
      } else if (value === 2) {
        // payment in 7 days
        this.search.billed_on = {
          min: new Date(t.getFullYear(), t.getMonth(), t.getDate() - 7),
          max: t,
        };
      } else if (value === 3) {
        // payment in 30 days
        this.search.billed_on.min = new Date(
          t.getFullYear(),
          t.getMonth(),
          t.getDate() - 30
        );
        this.search.billed_on.max = t;
      } else if (value === 4) {
        // payment in 6 months
        this.search.billed_on.min = new Date(t.getFullYear(), t.getMonth() - 6);
        this.search.billed_on.max = t;
      } else if (value === 5) {
        // payment in 1 year
        this.search.billed_on.min = new Date(
          t.getFullYear(),
          t.getMonth() - 12
        );
        this.search.billed_on.max = t;
      } else if (value === 6) {
        // payment in 2 year
        this.search.billed_on.min = new Date(
          t.getFullYear(),
          t.getMonth() - 24
        );
        this.search.billed_on.max = t;
      }

      this.loadList();
    },
  },
  watch: {
    options: {
      handler() {
        this.setURLParams();
      },
      deep: true,
    },
    search: {
      handler: debounce(function () {
        this.options.page = 1;
        this.setURLParams();
      }),
      deep: true,
    },
    clusterId() {
      this.loadList();
    },
  },
};
</script>
